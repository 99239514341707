import Card from "@/components/shared-ui/Card";
import * as Styled from "./styled";
import Page from "@/components/layout/Page";
import Button from "@/components/shared-ui/Button";
import TasksCard from "./TasksCard";
import Illustration from "../../assets/dashboard-illustration.svg";
import ContactCard from "@/components/shared-ui/ContactCard";
import useCompany from "@/contexts/CompanyContext";
import UpdatesCard from "./UpdatesCard";
import { useAuth0 } from "@auth0/auth0-react";
import LeftBorderCard from "@/components/shared-ui/LeftBorderCard";

const Dashboard = () => {
  const { user } = useAuth0();
  const { company_name, airtable_view, firm_id } = useCompany();

  const isFullUser =
    user && user["https://customer.seccl.tech/roles"].includes("all_features");

  return (
    <Page title="Dashboard" isDashboard>
      <Styled.Dashboard>
        <Card
          gridColumn="1 / -1"
          noPadding
          featureBackground={{ full: true, gradient: "light2" }}
        >
          <Styled.WelcomeCard>
            <Styled.WelcomeCardInner>
              <div>
                <h1 data-testid="companyName">
                  {company_name} & Seccl:
                  <Styled.AccentHeading>your customer hub</Styled.AccentHeading>
                </h1>
              </div>
              <Styled.WelcomeCardImageSection>
                <strong>
                  Welcome to your customer hub – home to the key resources
                  you’ll need to launch, operate and scale your platform on
                  Seccl.
                </strong>
                <img src={Illustration} alt="" />
              </Styled.WelcomeCardImageSection>
            </Styled.WelcomeCardInner>
            <Styled.UpdatesCard>
              <Styled.CardHeading>Important updates</Styled.CardHeading>
              <UpdatesCard />
            </Styled.UpdatesCard>
            {isFullUser && firm_id && (
              <LeftBorderCard
                heading="Platform setup"
                content="Find details of your platform configuration and key
                  proposition details"
                internalLink="platform-management"
                buttonLabel="Find out more"
                gridColumn="1"
              />
            )}
            <LeftBorderCard
              heading="Product roadmap"
              content="Find the latest updates on upcoming products"
              internalLink="product-roadmap"
              buttonLabel="Find out more"
              gridColumn="2"
            />
          </Styled.WelcomeCard>
        </Card>

        <Card
          gridColumn={isFullUser ? "1" : "1 / 3"}
          featureBackground={{ gradient: "light2" }}
        >
          <Styled.CardContent>
            <Styled.CardHeading>Process documentation</Styled.CardHeading>
            <p>
              Find detailed information on the operational and technical
              processes involved in launching and running a platform on Seccl’s
              technology.
            </p>
          </Styled.CardContent>
          <Button
            data-testid="processDocsButton"
            variant="secondary"
            internalLink="/docs"
            label="All documents"
            centered
          />
        </Card>

        {isFullUser && (
          <Card
            gridColumn={["1", "2"]}
            featureBackground={{ gradient: "light2" }}
          >
            <Styled.CardContent>
              <Styled.CardHeading>Useful resources</Styled.CardHeading>
              <p>
                Browse our growing range of guides and supporting material, and
                find important documents relating to how we work together.
              </p>
            </Styled.CardContent>
            <Button
              data-testid="usefulResourcesButton"
              variant="secondary"
              internalLink="useful-resources"
              label="All documents"
              centered
            />
          </Card>
        )}

        <Card gridColumn="1">
          <Styled.CardHeading>Due diligence</Styled.CardHeading>
          <p>
            Carry out your due diligence on Seccl with the help of our
            comprehensive FAQ library.
          </p>
          <Button
            variant="tertiary"
            internalLink="due-diligence"
            label="See documents"
          />
        </Card>

        <Card gridColumn={["1", "2"]}>
          <Styled.CardHeading>
            How to use our professional portal
          </Styled.CardHeading>
          <p>
            {
              "Watch our series of bitesize training videos to find detailed, step-by-step instructions on how to carry out key actions within our 'Professional UI'."
            }
          </p>
          <Button
            variant="tertiary"
            internalLink="system-training"
            label="See training videos"
          />
        </Card>

        {isFullUser && airtable_view && (
          <Card gridColumn="1 / -1" data-testid="tasksCard">
            <Styled.CardHeading>Task tracker</Styled.CardHeading>
            <TasksCard />
          </Card>
        )}

        <ContactCard />
      </Styled.Dashboard>
    </Page>
  );
};

export default Dashboard;
