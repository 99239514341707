import NotFound from "@/pages/NotFound";
import { useAuth0 } from "@auth0/auth0-react";

type Props = {
  component: React.ComponentType<object>;
  roleRequired: string;
};

const RoleGuard = ({ component, roleRequired }: Props) => {
  const { user } = useAuth0();
  const Component = component;

  if (
    user &&
    user["https://customer.seccl.tech/roles"].includes(roleRequired)
  ) {
    return <Component />;
  } else {
    return <NotFound />;
  }
};

export default RoleGuard;
