import { Link } from "react-router-dom";
import * as Styled from "./styled";
import { ReactComponent as ArrowRight } from "@assets/icons/arrow-right.svg";

export type ButtonVariant = "secondary" | "tertiary";

type Props = {
  label: string;
  variant?: ButtonVariant;
  inverse?: boolean;
  internalLink?: string;
  centered?: boolean;
  noArrow?: boolean;
  noWrap?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button = ({ label, internalLink, noArrow, ...props }: Props) => {
  const buttonCode = (
    <Styled.Button {...props}>
      <>
        {label} {!noArrow && <ArrowRight />}
      </>
    </Styled.Button>
  );
  if (internalLink) {
    return <Link to={internalLink}>{buttonCode}</Link>;
  }
  return buttonCode;
};

export default Button;
