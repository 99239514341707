import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Documentation from "../pages/Documentation";
import { ApiProvider } from "../contexts/ApiContext";
import Tasks from "@/pages/Tasks";
import { CompanyProvider } from "@/contexts/CompanyContext";
import UsefulResources from "@/pages/UsefulResources";
import CustomErrorBoundary from "@/components/utility/CustomErrorBoundary";
import Contact from "@/pages/Contact";
import { ErrorBoundary } from "react-error-boundary";
import ErrorScreen from "@/components/shared-ui/ErrorScreen";
import NotFound from "@/pages/NotFound";
import SystemTraining from "@/pages/SystemTraining";
import { DocsActiveHeadingProvider } from "@/contexts/DocsActiveHeadingContext";
import DueDiligence from "@/pages/DueDiligence";
import AdminDashboard from "@/pages/admin/AdminDashboard";
import AdminUserManagement from "@/pages/admin/AdminUserManagement";
import AdminUserEdit from "@/pages/admin/AdminUserEdit";
import AdminUserCreate from "@/pages/admin/AdminUserCreate";
import UpdatesList from "@/pages/UpdatesList";
import Update from "@/pages/Update";
import RoleGuard from "@/components/utility/RoleGuard";
import PlatformManagement from "@/pages/PlatformManagement";
import ProductRoadmap from "@/pages/ProductRoadmap";
import ProductFruitsSurvey from "@/components/utility/ProductFruitsSurvey";
import * as Sentry from "@sentry/react";

const Routing = () => {
  const logSentryError = (error: Error) => {
    Sentry.captureException(error);
  };

  return (
    // API Provider must be within authenticated environment
    <ApiProvider>
      <ErrorBoundary fallback={<ErrorScreen topLevel />} onError={logSentryError}>
        <CompanyProvider>
          <ProductFruitsSurvey />
          <Routes>
            <Route
              element={
                <CustomErrorBoundary handleError={logSentryError}>
                  <Outlet />
                </CustomErrorBoundary>
              }
            >
              {/* Customer section */}
              <Route path="/" element={<Dashboard />} />
              <Route
                path="/docs"
                element={
                  <Navigate replace to="/docs/welcome/getting-started" />
                }
              />
              <Route
                path={`/docs/:docSlug/:sectionSlug`}
                element={
                  <DocsActiveHeadingProvider>
                    <Documentation />
                  </DocsActiveHeadingProvider>
                }
              />
              <Route
                path="/due-diligence"
                element={<Navigate replace to="/due-diligence/overview" />}
              />
              <Route
                path={`/due-diligence/:ddSlug`}
                element={<DueDiligence />}
              />
              <Route
                path="/system-training"
                element={
                  <Navigate
                    replace
                    to="/system-training/seccl-system-training-introduction"
                  />
                }
              />
              <Route
                path="/system-training/:videoSlug"
                element={<SystemTraining />}
              />
              <Route path="/updates" element={<UpdatesList />} />
              <Route
                path="/updates/:updateId"
                element={
                  <DocsActiveHeadingProvider>
                    <Update />
                  </DocsActiveHeadingProvider>
                }
              />
              <Route
                path="/tasks"
                element={
                  <RoleGuard roleRequired="all_features" component={Tasks} />
                }
              />
              <Route
                path="/useful-resources"
                element={
                  <RoleGuard
                    roleRequired="all_features"
                    component={UsefulResources}
                  />
                }
              />
              <Route
                path="/platform-management"
                element={
                  <RoleGuard
                    roleRequired="all_features"
                    component={PlatformManagement}
                  />
                }
              />
              <Route
                path="/product-roadmap"
                element={<ProductRoadmap />}
              />
              <Route path="/contact" element={<Contact />} />
              {/* Admin section */}
              <Route
                path="/admin"
                element={
                  <RoleGuard roleRequired="admin" component={AdminDashboard} />
                }
              />
              <Route
                path="/admin/users"
                element={
                  <RoleGuard
                    roleRequired="admin"
                    component={AdminUserManagement}
                  />
                }
              />
              <Route
                path="/admin/users/create"
                element={
                  <RoleGuard roleRequired="admin" component={AdminUserCreate} />
                }
              />
              <Route
                path="/admin/users/edit/:userId"
                element={
                  <RoleGuard roleRequired="admin" component={AdminUserEdit} />
                }
              />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </CompanyProvider>
      </ErrorBoundary>
    </ApiProvider>
  );
};

export default Routing;
